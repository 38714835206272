import { useEffect, useState } from "react";
import { Dialog, Box } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import { StatesAccents } from "../../../mapbox/states";
import { useIntl } from "react-intl";
import voca from "voca";

const RaceboardProject = ({ open, onClose, app }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const year = useSelector((state) => state.year.value);
  const raceType = useSelector((state) => state.raceType.value);

  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [row1L, setRow1L] = useState('');
  const [row1R, setRow1R] = useState('');
  const [row2L, setRow2L] = useState('');
  const [row2R, setRow2R] = useState('');
  const [row3L, setRow3L] = useState('');
  const [row3R, setRow3R] = useState('');
  const [img1, setImg1] = useState('');
  const [img2, setImg2] = useState('');
  const [barColor, setBarColor] = useState('red');  

  useEffect(() => {
    
    let level = app.raceboard.layer;
    let lev = 'state';
    if (level === 'districts')
      lev = 'district';
    else if (level === 'municipals')
      lev = 'municipal' 
  
    let value = '';
    let title = '';
    let subTitle = '';
    let row1L = '';
    let row1R = '';
    let row2L = '';
    let row2R = '';
    let row3L = '';
    let row3R = '';
    let img1 = '';
    let img2 = '';
    if (app.projectData[app.projectYearID] && app.projectData[app.projectYearID][lev]) {       
      const data = app.projectData[app.projectYearID][lev];
      for (let i = 0; i < data.length; i++) {
        if (data[i].length > 1) {
          if (level === 'states') {
            if (app.raceboard.stateId === parseInt(data[i][0])) {
              value = data[i][1] ? data[i][1].trim() : '';
              title = data[i][2] ? data[i][2].trim() : '';
              subTitle = data[i][3] ? data[i][3].trim() : '';
              row1L = data[i][4] ? data[i][4].trim() : '';
              row1R = data[i][5] ? data[i][5].trim() : '';
              row2L = data[i][6] ? data[i][6].trim() : '';
              row2R = data[i][7] ? data[i][7].trim() : '';
              row3L = data[i][8] ? data[i][8].trim() : '';
              row3R = data[i][9] ? data[i][9].trim() : '';
              img1 = data[i][10] ? data[i][10].trim() : '';
              img2 = data[i][11] ? data[i][11].trim() : '';
            }
          }
          else if (level === 'districts') {
            //to do...
          }
          else if (level === 'municipals') {
            if (app.raceboard.munId === data[i][0] + data[i][1]) {
              value = data[i][2] ? data[i][2].trim() : '';
              title = data[i][3] ? data[i][3].trim() : '';
              subTitle = data[i][4] ? data[i][4].trim() : '';
              row1L = data[i][5] ? data[i][5].trim() : '';
              row1R = data[i][6] ? data[i][6].trim() : '';
              row2L = data[i][7] ? data[i][7].trim() : '';
              row2R = data[i][8] ? data[i][8].trim() : '';
              row3L = data[i][9] ? data[i][9].trim() : '';
              row3R = data[i][10] ? data[i][10].trim() : '';
              img1 = data[i][11] ? data[i][11].trim() : '';
              img2 = data[i][12] ? data[i][12].trim() : '';
            }
          }
        }
      }
      
      let mun = app.raceboard.layer === "municipals"
            ? app.raceboard.mun === "Torreon"
              ? "Torreón"
              : app.raceboard.mun
            : app.raceboard.state === "México"
            ? intl.formatMessage({ id: "MexicoState" })
            : voca.titleCase(
                StatesAccents[app.raceboard.state.toUpperCase()] ||
                  app.raceboard.state.toUpperCase() ||
                  ""
              );
              
      let state = app.raceboard.state === "México"
            ? intl.formatMessage({ id: "MexicoState" })
            : voca.titleCase(
                StatesAccents[app.raceboard.state.toUpperCase()] ||
                  app.raceboard.state.toUpperCase() ||
                  ""
              );
              
      if (app.raceboard.munId === '20037')
        mun = 'Huajuapan de León';
      else if (app.raceboard.munId === '20549')
        mun = 'Tezoatlán de Segura y Luna';
      else if (app.raceboard.munId === '20553')
        mun = 'Tlaxiaco';
      else if (app.raceboard.munId === '20028')
        mun = 'Ejutla de Crespo';
      else if (app.raceboard.munId === '11014')
        mun = 'Dolores Hidalgo';
      else if (app.raceboard.munId === '13056')
        mun = 'Santiago Tulantepec';
      else if (app.raceboard.munId === '16093')
        mun = 'Tiquicheo';
      else if (app.raceboard.munId === '20053')
        mun = 'Magdalena Yodocono';
      else if (app.raceboard.munId === '20539')
        mun = 'Tamazulápam del Progreso';
      else if (app.raceboard.munId === '20543')
        mun = 'Tejúpam de la Unión';
      else if (app.raceboard.munId === '29037')
        mun = 'Ziltlaltépec';
      else if (app.raceboard.munId === '30205')
        mun = 'Nanchital';
      else if (app.raceboard.munId === '12069')
        mun = 'La Unión';
      else if (app.raceboard.munId === '8008')
        mun = 'Batopilas de Gómez Morín';
      else if (app.raceboard.munId === '29017')
        mun = 'Mazatecochco';
      else if (app.raceboard.munId === '12016')
        mun = 'Coahuayutla';
      else if (app.raceboard.munId === '30202')
        mun = 'Zontecomatlán';
      else if (app.raceboard.munId === '30010')
        mun = 'Alto Lucero';
      else if (app.raceboard.munId === '20334')
        mun = 'Pedro y Pablo Teposcolula';
      else if (app.raceboard.munId === '20335')
        mun = 'Pedro y Pablo Tequixtepec';      
      else if (app.raceboard.munId === '26070')
        mun = 'Gral. Plutarco Elías Calles';
      else if (app.raceboard.munId === '4012')
        mun = 'Dzitbalche';
      else if (app.raceboard.munId === '4013')
        mun = 'Seybaplaya';
      
      if (title.match('#STATE#'))
        title = title.replace('#STATE#', state);
      if (title.match('#MUNICIPAL#'))
        title = title.replace('#MUNICIPAL#', mun);
       
      if (subTitle.match('#STATE#'))
        subTitle = subTitle.replace('#STATE#', state);
      if (subTitle.match('#MUNICIPAL#'))
        subTitle = subTitle.replace('#MUNICIPAL#', mun);
    
      if (row1L.match('#STATE#'))
        row1L = row1L.replace('#STATE#', state);
      if (row1L.match('#MUNICIPAL#'))
        row1L = row1L.replace('#MUNICIPAL#', mun);
    
      if (row1R.match('#STATE#'))
        row1R = row1R.replace('#STATE#', state);
      if (row1R.match('#MUNICIPAL#'))
        row1R = row1R.replace('#MUNICIPAL#', mun);
    
      if (row2L.match('#STATE#'))
        row2L = row2L.replace('#STATE#', state);
      if (row2L.match('#MUNICIPAL#'))
        row2L = row2L.replace('#MUNICIPAL#', mun);
    
      if (row2R.match('#STATE#'))
        row2R = row2R.replace('#STATE#', state);
      if (row2R.match('#MUNICIPAL#'))
        row2R = row2R.replace('#MUNICIPAL#', mun);
    
      if (row3L.match('#STATE#'))
        row3L = row3L.replace('#STATE#', state);
      if (row3L.match('#MUNICIPAL#'))
        row3L = row3L.replace('#MUNICIPAL#', mun);
    
      if (row3R.match('#STATE#'))
        row3R = row3R.replace('#STATE#', state);
      if (row3R.match('#MUNICIPAL#'))
        row3R = row3R.replace('#MUNICIPAL#', mun);
      
      setTitle(title);
      setSubTitle(subTitle);
      setRow1L(row1L);
      setRow1R(row1R);
      setRow2L(row2L);
      setRow2R(row2R);
      setRow3L(row3L);
      setRow3R(row3R);
      setImg1(img1);
      setImg2(img2);
      
      //set bar color
      let bar_color = 'red';
      if (app.projectColorType === 'Category') {
        let pcCategory = app.projectColorCategory;
        if (app.projectYearData[app.projectYearID].use_different_colors_mun && app.projectYearColorCategory['mun_year_' + app.projectYearID] && level === 'municipals')
          pcCategory = app.projectYearColorCategory['mun_year_' + app.projectYearID];
        if (app.projectYearData[app.projectYearID].use_different_colors && app.projectYearColorCategory['year_' + app.projectYearID])
          pcCategory = app.projectYearColorCategory['year_' + app.projectYearID];
        for (let i = 0; i < pcCategory.length; i++) {
          if (value === pcCategory[i].value) {
            bar_color = pcCategory[i].color;
          }
        }
      }
      else if (app.projectColorType === 'Group') {    
        let pcGroups = app.projectColorGroups;
        if (app.projectYearData[app.projectYearID].use_different_colors_mun && app.projectYearColorGroups['mun_year_' + app.projectYearID] && level === 'municipals')
          pcGroups = app.projectYearColorGroups['mun_year_' + app.projectYearID];
        else if (app.projectYearData[app.projectYearID].use_different_colors && app.projectYearColorGroups['year_' + app.projectYearID])
          pcGroups = app.projectYearColorGroups['year_' + app.projectYearID];
      
        //get max and min values
        let min = -1;
        let min_color = '';
        let max = -1;
        let max_color = '';
        for (let i = 0; i < pcGroups.length; i++) {
          let min_val = parseFloat(pcGroups[i].min_value);
          let max_val = parseFloat(pcGroups[i].max_value);
          if (min === -1 || min_val < min) {
              min = min_val;
              min_color = pcGroups[i].color;
          }
          if (max === -1 || max_val > max) {
              max = max_val;
              max_color = pcGroups[i].color;
          }
        }

        let val = parseFloat(value.replace(/[^\d.-]/g, ''));
        if (val < min)
          bar_color = min_color;
        else if (val > max)
          bar_color = max_color;
        else {
          for (let i = 0; i < pcGroups.length; i++) {
            let min_val = parseFloat(pcGroups[i].min_value);
            let max_val = parseFloat(pcGroups[i].max_value);
            if (val >= min_val && val <= max_val)
              bar_color = pcGroups[i].color;
          }
        }                
      }
      else {
          //find max and min values
          const data = app.projectData[app.projectYearID][lev];
          let values = [];                      
          for (let i = 0; i < data.length; i++) {
            if (data[i].length > 1) {
              if (level === 'states') {
                values.push(parseFloat(data[i][1].replace(/[^\d.-]/g, '')));
              }
              else if (level === 'districts') {
                values.push(parseFloat(data[i][2].replace(/[^\d.-]/g, '')));
              }
              else if (level === 'municipals') {
                values.push(parseFloat(data[i][2].replace(/[^\d.-]/g, '')));
              }
            }
          }           

          let max = app.projectMaxValue ? app.projectMaxValue : Math.max(...values);
          let min = app.projectMinValue === '' ? Math.min(...values) : app.projectMinValue;   
          let color1 = app.projectMaxColor.substr(1);
          let color2 = app.projectMinColor.substr(1);
          
          if (app.projectYearData[app.projectYearID].use_different_colors_mun && level === 'municipals') {
            max = app.projectYearData[app.projectYearID].max_value_mun ? app.projectYearData[app.projectYearID].max_value_mun : Math.max(...values);
            min = app.projectYearData[app.projectYearID].min_value_mun === '' ? Math.min(...values) : app.projectYearData[app.projectYearID].min_value_mun; 
            color1 = app.projectYearData[app.projectYearID].max_color_mun.substr(1);
            color2 = app.projectYearData[app.projectYearID].min_color_mun.substr(1);
          }
          else if (app.projectYearData[app.projectYearID].use_different_colors) {
            max = app.projectYearData[app.projectYearID].max_value ? app.projectYearData[app.projectYearID].max_value : Math.max(...values);
            min = app.projectYearData[app.projectYearID].min_value === '' ? Math.min(...values) : app.projectYearData[app.projectYearID].min_value; 
            color1 = app.projectYearData[app.projectYearID].max_color.substr(1);
            color2 = app.projectYearData[app.projectYearID].min_color.substr(1);
          }
          
          let val = parseFloat(value.replace(/[^\d.-]/g, ''));
          
          if (val > max)
            val = max;
        
          //get color ratio
          let ratio = (val - min) / (max - min);
        
          let r = Math.ceil(parseInt(color1.substring(0,2), 16) * ratio + parseInt(color2.substring(0,2), 16) * (1-ratio));
          let g = Math.ceil(parseInt(color1.substring(2,4), 16) * ratio + parseInt(color2.substring(2,4), 16) * (1-ratio));
          let b = Math.ceil(parseInt(color1.substring(4,6), 16) * ratio + parseInt(color2.substring(4,6), 16) * (1-ratio));

          const hex = function(x) {
            x = x.toString(16);
            return (x.length === 1) ? '0' + x : x;
          };

          let middle = hex(r) + hex(g) + hex(b);
          bar_color = '#' + middle;
      }
      setBarColor(bar_color);
    } 
  }, [year, raceType, dispatch, app.projectData, app.projectYearID, app.raceboard, app.projectColorCategory, app.projectColorGroups, app.projectColorType, app.projectMaxColor, app.projectMaxValue, app.projectMinColor, app.projectMinValue, app.projectYearColorCategory, app.projectYearColorGroups, app.projectYearData, intl]);

  return (
    <Dialog
      id={"raceboardP"}
      opened={open}
      withCloseButton
      onClose={onClose}
      radius="unset"
      position={{
        top: 0,
        left: app.reverse ? "1%" : "auto",
        right: app.reverse ? "auto" : "1%"
      }}
      transition="slide-left"
      transitionDuration={0}
      sx={{
        display: (!title && !subTitle && !row1L && !row1R) ? "none" : "flex",
        flexDirection: "row",
        padding: "0px !important",
        fontSize: "16px",
        color: "#111111",
        top: window.innerWidth > 3000 ? 220 : 89,
        width: "auto",
        background: "transparent",
        boxShadow: "none"
      }}
    >
      <Box
        sx={{
          width: window.innerWidth > 3000 ? 1200 : 700,
          marginRight: window.innerWidth > 3000 ? 0 : 0
        }}
      >
        <Box className={"raceboardNWomen"} sx={{backgroundColor: "#595e79 !important", color: "#eeedfd"}}>
          <Box sx={{height: "20px"}}></Box>
          <Box
            className={"raceboardPRTitle" + (title.length >= 28 ? " projectLong" : "")}
            sx={{
              fontSize: "50px",
              lineHeight: "96px",
              height: "96px",
              width: "100%",
              fontWeight: "bold",
              margin: "0px 2%",
              display: title ? 'block' : 'none', 
              whiteSpace: "nowrap"
            }}
          >
            {title}
          </Box>
          <Box
            className={"raceboardPRSubtitle"}
            sx={{
              fontSize: "40px",
              lineHeight: "45px",
              paddingBottom: "0px",
              paddingTop: "0px",
              fontWeight: 300,
              textAlign: "left",
              margin: "0px 2%",
              marginTop: title ? "-20px" : "20px",
              display: subTitle ? 'block' : 'none', 
              whiteSpace: "nowrap"
            }}
          >            
            {subTitle}
          </Box>
          <Box
            className={"raceboardPRTopSpace"} 
            sx={{
              width: "92%",
              flexDirection: "row",
              paddingBottom: "17px",
              marginLeft: "8%",
              paddingTop: "20px",
              display: (row1L || row1R) ? 'flex' : 'none', 
            }}
          >
            <Box className={row1R ? '' : 'raceboardRedBarFull'} style={{backgroundColor: row1R ? 'inherit' : barColor}}>&nbsp;</Box>
            {row1L.substring(0,4) !== 'http' ? <div class={row1R ? 'raceboardRedBarHalf' : ''} style={{backgroundColor: barColor}}></div> : ''}
            <Box
              className={"raceboardPRTextL"}
              sx={{
                width: row1R ? "50%" : "100%",
                fontSize: "38px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row1L.substring(0,4) === 'http' && <img src={row1L} alt="" class={'projectRowImg'} />}
              {row1L.substring(0,4) !== 'http' ? row1L : ''}
            </Box>
            {row1L.substring(0,4) === 'http' ? <div class={'raceboardRedBarHalf'} style={{backgroundColor: barColor}}></div> : ''}
            <Box
              className={"raceboardPRTextR"}
              sx={{
                width: row1R ? "50%" : "0%",
                fontWeight: "300",
                fontSize: "82px",
                lineHeight: "72px",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row1R.substring(0,4) === 'http' && <img src={row1R} alt="" class={'projectRowImg'} />}
              {row1R.substring(0,4) !== 'http' ? row1R : ''}
            </Box>
          </Box>
          <Box
            sx={{
              width: "92%",
              flexDirection: "row",
              paddingBottom: "17px",
              borderTop: "solid 1px",
              marginLeft: "8%",
              paddingTop: "20px",
              display: (row2L || row2R) ? 'flex' : 'none', 
            }}
          >
            <Box className={row2R ? '' : 'raceboardRedBarFull'} style={{backgroundColor: row2R ? 'inherit' : barColor}}>&nbsp;</Box>
            {row2L.substring(0,4) !== 'http' ? <div class={row2R ? 'raceboardRedBarHalf' : ''} style={{backgroundColor: barColor}}></div> : ''}
            <Box
              className={"raceboardPRTextL"}
              sx={{
                width: row2R ? "50%" : "100%",
                fontSize: "38px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row2L.substring(0,4) === 'http' && <img src={row2L} alt="" class={'projectRowImg'} />}
              {row2L.substring(0,4) !== 'http' ? row2L : ''}
            </Box>
            {row2L.substring(0,4) === 'http' ? <div class={'raceboardRedBarHalf'} style={{backgroundColor: barColor}}></div> : ''}
            <Box
              className={"raceboardPRTextR"}
              sx={{
                width: row2R ? "50%" : "0%",
                fontWeight: "300",
                fontSize: "82px",
                lineHeight: "72px",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row2R.substring(0,4) === 'http' && <img src={row2R} alt="" class={'projectRowImg'} />}
              {row2R.substring(0,4) !== 'http' ? row2R : ''}
            </Box>
          </Box>
          <Box
            sx={{
              width: "92%",
              flexDirection: "row",
              paddingBottom: "12px",
              borderTop: "solid 1px",
              marginLeft: "8%",
              paddingTop: "20px",
              display: (row3L || row3R) ? 'flex' : 'none', 
            }}
          >
            <Box className={row3R ? '' : 'raceboardRedBarFull'} style={{backgroundColor: row3R ? 'inherit' : barColor}}>&nbsp;</Box>
            {row3L.substring(0,4) !== 'http' ? <div class={row3R ? 'raceboardRedBarHalf' : ''} style={{backgroundColor: barColor}}></div> : ''}
            <Box
              className={"raceboardPRTextL"}
              sx={{
                width: row3R ? "50%" : "100%",
                fontSize: "38px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row3L.substring(0,4) === 'http' && <img src={row3L} alt="" class={'projectRowImg'} />}
              {row3L.substring(0,4) !== 'http' ? row3L : ''}
            </Box>
            {row3L.substring(0,4) === 'http' ? <div class={'raceboardRedBarHalf'} style={{backgroundColor: barColor}}></div> : ''}
            <Box
              className={"raceboardPRTextR"}
              sx={{
                width: row3R ? "50%" : "0%",
                fontWeight: "300",
                fontSize: "82px",
                lineHeight: "72px",
                display: "flex",
                alignItems: "end"
              }}
            >
              {row3R.substring(0,4) === 'http' && <img src={row3R} alt="" class={'projectRowImg'} />}
              {row3R.substring(0,4) !== 'http' ? row3R : ''}
            </Box>
          </Box>
          <Box className={"raceboardPRBottomSpace"} sx={{height: "44px"}}></Box>
        </Box>
        
        <Box 
          sx={{            
            marginTop: "4px",
            borderRadius: "5px",
            display: img1 ? 'flex' : 'none', 
          }}
        >         
          <Box
            sx={{
              width: img2 ? "50%" : "100%",
              display: "flex"
            }}
          >
            <img className={"raceboardNWomen"} src={img1} alt="" style={{ width: "100%", backgroundColor: "transparent" }} />
          </Box>
          <Box
            sx={{
              width: img2 ? "49%" : "0%",
              marginLeft: img2 ? "1%" : "0%",
              display: "flex"
            }}
          >
            <img className={"raceboardNWomen"} src={img2} alt="" style={{ width: "100%", backgroundColor: "transparent" }} />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RaceboardProject;
